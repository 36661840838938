import React from "react"
import Layout from "../../components/layout";
import {Col, Row, Container, Button} from "react-bootstrap";
import productStyles from "./clothes.module.css";
import Img from "gatsby-image";
import {graphql, Link} from "gatsby";
import globalStyle from "../../styles/global.module.css";
import clothesStyle from "./clothes.module.css";

const ClothesPage = ({data}) => {

  return (
    <Layout className="site-content">
      <Container>
      <Row className={["justify-content-center"].join(" ")}>
        <Col md={12} className={productStyles.shopsPageContent}>
          <h2 className={globalStyle.entryTitle}>Treat Yourself</h2>

            <Row className={[productStyles.bannersContent, clothesStyle.productsLine]}>
              {data.products.edges.map(({node}) => (
              <Col xs={12} md={4} sm={4} key={node.id} className= {[clothesStyle.productsLine + " text-center"]} >
                <Row className={productStyles.photoContent}>
                  {/*<Col>*/}
                  <Link to={node.frontmatter.path}>
                  <Img className={productStyles.frontPhoto} fluid={node.frontmatter.photo['childImageSharp']['fluid']} />
                  <Img id="backPhoto" className={productStyles.backPhoto} fluid={node.frontmatter.photoBack['childImageSharp']['fluid']} />
                  </Link>
                  {/*</Col>*/}
                </Row>
                <Row className="justify-content-left">
                  <Col>
                  <p className={productStyles.productName}>{node.frontmatter.title}</p>
                  </Col>
                </Row>
                <Row className="justify-content-left">
                  <Col>
                  <p className={productStyles.price}>{node.frontmatter.price}</p>
                  </Col>
                </Row>
                <Row className="justify-content-left">
                  <Col>
                  <Link to={node.frontmatter.path}>
                    <Button className={[globalStyle.btnPrimary, globalStyle.redirectButtonPink, "viewDetailsButton"].join(" ")} role="button">
                      <i className={[productStyles.viewDetailsIcon, "fas fa-angle-double-right"].join(" ")}></i>
                      View details
                    </Button>
                  </Link>
                  </Col>
                </Row>
              </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
};


export const query = graphql`
  query {
    products: allMarkdownRemark(
      filter: {frontmatter: {posttype: {eq: "product"}}},
    ) {
      totalCount
      edges {
        node {
          id
          rawMarkdownBody
          frontmatter {
            path
            title
            density
            posttype
            composition
            description
            price
            stoc
            sizes
            photo {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            photoBack {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          excerpt
        }
      }
    }
  }
`;

export default ClothesPage
